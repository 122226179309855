<template>
  <div class="son-page">
    <div class="Finance_box">
      <div class="Finance_box_title DW_nei_txt">店铺财务</div>
      <div class="view_right_num">
        <div style="border-right: 1px solid #eee;" class="item_right_num">
          <div class="item_title">总订单数</div>
          <div class="item_num">{{home.order_count}}</div>
        </div>
        <div style="border-right: 1px solid #eee;" class="item_right_num">
          <div class="item_title">总营业额</div>
          <div class="item_num">{{home.order_money}}</div>
        </div>
        <div style="border-right: 1px solid #eee;" class="item_right_num">
          <div class="item_title">用户数</div>
          <div class="item_num">{{home.user_count}}</div>
        </div>
        <div class="item_right_num">
          <div class="item_title">会员数</div>
          <div class="item_num">{{home.member_count}}</div>
        </div>
      </div>
    </div>
    <div class="top-change">
      <div class="shu_box">
        <div>
          <div v-if="home.money_arr" class="Finance_box_title DW_nei_txt">实收总额：￥{{home.order_pay_sum}}</div>
          <EchartBasic v-if="piestatus1" id="wahaa" :data="basicData" width="100%"></EchartBasic>
        </div>
      </div>
      <div class="bing_box">
        <div>
          <div v-if="home.money_arr" class="Finance_box_title DW_nei_txt">优惠总额：￥{{home.money_arr.discount_money_sum}}</div>
          <div style="display:flex;align-items: center;height: 100%;">
            <Pie v-if="piestatus1" id="abcde" :color="['#5DAEFF','#FF761D','#92C411','#FF771C']" :labelshow="true" :data="piedata2" :width="'300px'" :height="'200'"></Pie>
          </div>
        </div>
      </div>
    </div>
    <div style="background:#FFF;border-radius:12px">
      <div class="top-search">
        <DatePicker @on-change="time" type="daterange" placement="bottom" placeholder="点击选择日期" style="width: 240px"></DatePicker>
        <Button @click="searchBtn" style="margin-left:30px" class="btn">搜索</Button>
      </div>
      <div class="three_box">
        <div class="item_three_box">
          <div class="just_sb">
            <div class="DW_nei_txt">
              <div><img style="width:20px;height:20px;margin:4px 10px 0 0" src="@/assets/wm.png" alt=""></div>
              <div>外卖</div>
            </div>
            <div><Button @click="Paymentdetails(2)" class="btn" size="small">明细</Button></div>
          </div>
          <div class="view_right_num">
            <div style="border-right: 1px dashed #eee;text-align:center;width:50%">
              <div>营业额</div>
              <div class="item_num">{{data.w_money}}</div>
            </div>
            <div style="text-align:center;width:50%">
              <div>订单数</div>
              <div class="item_num">{{data.w_count}}</div>
            </div>
          </div>
        </div>
        <div style="border-left:1px solid #eee" class="item_three_box">
          <div class="just_sb">
            <div class="DW_nei_txt">
              <div><img style="width:20px;height:20px;margin:4px 10px 0 0" src="@/assets/st.png" alt=""></div>
              <div>堂食</div>
            </div>
            <div><Button @click="Paymentdetails(1)" class="btn" size="small">明细</Button></div>
          </div>
          <div class="view_right_num">
            <div style="border-right: 1px dashed #eee;text-align:center;width:50%">
              <div>营业额</div>
              <div class="item_num">{{data.t_money}}</div>
            </div>
            <div style="text-align:center;width:50%">
              <div>订单数</div>
              <div class="item_num">{{data.t_count}}</div>
            </div>
          </div>
        </div>
        <div style="border-left:1px solid #eee" class="item_three_box">
          <div class="just_sb">
            <div class="DW_nei_txt">
              <div><img style="width:20px;height:20px;margin:4px 10px 0 0" src="@/assets/zq.png" alt=""></div>
              <div>自取</div>
            </div>
            <div><Button @click="Paymentdetails(3)" class="btn" size="small">明细</Button></div>
          </div>
          <div class="view_right_num">
            <div style="border-right: 1px dashed #eee;text-align:center;width:50%">
              <div>营业额</div>
              <div class="item_num">{{data.z_money}}</div>
            </div>
            <div style="text-align:center;width:50%">
              <div>订单数</div>
              <div class="item_num">{{data.z_count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_box">
      <div class="turnover_box">
        <Select @on-change="D_change" v-model="D_model" style="width:200px">
            <Option v-for="item in turnoverList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <EchartLine v-if="status" id="huitai" :legend="legend" :xAxis="xAxis" :series="series" width="100%"></EchartLine>
    </div>
    <div style="background:#FFF">
      <div class="pie_select">
        <Select @on-change="pie_change" v-model="model1" style="width:200px;margin-left:30px">
            <Option v-for="item in orderList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div class="view_box">
        <div class="item_view view_left">
          <div class="nei_left">
            <div class="DW_nei">
              <div class="DW_nei_title DW_nei_txt">会员消费订单占比</div>
            </div>
            <Pie v-if="piestatus" id="abc" :color="['#5DAEFF','#FF761D','#92C411','#FF771C']" :data="piedata" :width="'300px'" :height="'200'"></Pie>
          </div>
          <div class="nei_right">
            <!-- <div class="pie_select">
              <Select v-model="model1" style="width:200px;margin-left:30px">
                  <Option v-for="item in orderList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div> -->
            <div class="view_title">
              <div class="w33">用户</div>
              <!-- <div class="w33">数量</div> -->
              <div class="w33">占比</div>
            </div>
            <div style="height:150px;overflow:auto">
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dian">会员用户</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{member.member_rate ? member.member_rate.toFixed(2) : 0}}%</div>
              </div>
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dianH">普通用户</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{member.user_rate ? member.user_rate.toFixed(2) : 0}}%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item_view view_left">
          <div class="nei_left">
            <div class="DW_nei">
              <div class="DW_nei_title2 DW_nei_txt">支付方式订单占比</div>
            </div>
            <Pie v-if="piestatus" id="abcd" :color="['#FF761D','#5DAEFF','#92C411','#ff0000']" :data="piedata1" :width="'300px'" :height="'200'"></Pie>
          </div>
          <div class="nei_right">
            <!-- <div class="pie_select">
              <Select v-model="model2" style="width:200px;margin-left:30px">
                  <Option v-for="item in orderList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </div> -->
            <div class="view_title">
              <div class="w33">用户</div>
              <!-- <div class="w33">数量</div> -->
              <div class="w33">占比</div>
            </div>
            <div style="height:150px;overflow:auto">
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dianH">余额单数</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{pay_type.yue_rate ? pay_type.yue_rate.toFixed(2) : 0}}%</div>
              </div>
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dian">支付宝单数</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{pay_type.zfb_rate ? pay_type.zfb_rate.toFixed(2) : 0}}%</div>
              </div>
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dianL">微信单数</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{pay_type.wx_rate ? pay_type.wx_rate.toFixed(2) : 0}}%</div>
              </div>
              <div class="view_shuju">
                <div style="display:flex;align-items: center;justify-content: center;" class="w33 dianX">现金单数</div>
                <!-- <div class="w33">100</div> -->
                <div class="w33">{{pay_type.cash_rate ? pay_type.cash_rate.toFixed(2) : 0}}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 支付明细弹框 -->
    <Modal v-model="paymentModal" width="500" :title="paymentTitle">
        <div style="padding:0 20px;color:#000;line-height:30px" class="dish_da_box">
          <div class="flex just-sb align-center size_18">
              <div v-if="paymentTitle=='堂食营业额明细'">堂食总营业额：</div>
              <div v-if="paymentTitle=='堂食营业额明细'">￥{{data.t_money}}</div>
              <div v-if="paymentTitle=='外卖营业额明细'">外卖总营业额：</div>
              <div v-if="paymentTitle=='外卖营业额明细'">￥{{data.w_money}}</div>
              <div v-if="paymentTitle=='自取营业额明细'">自取总营业额：</div>
              <div v-if="paymentTitle=='自取营业额明细'">￥{{data.z_money}}</div>
          </div>
          <div v-if="Number(PaymentData.order_pay_money)!=0" class="flex just-sb align-center size_18 color_FF8400">
            <div>实收金额</div>
            <div v-if="paymentTitle=='堂食营业额明细'">￥{{PaymentData.t_pay_money}}</div>
            <div v-if="paymentTitle=='外卖营业额明细'">￥{{PaymentData.w_pay_money}}</div>
            <div v-if="paymentTitle=='自取营业额明细'">￥{{PaymentData.z_pay_money}}</div>
          </div>
          <div v-if="PaymentData.wx_pay_money" class="flex just-sb align-center">
            <div>微信支付</div>
            <div>￥{{PaymentData.wx_pay_money}}</div>
          </div>
          <div v-if="PaymentData.yue_pay_money" class="flex just-sb align-center">
            <div>余额支付</div>
            <div>￥{{PaymentData.yue_pay_money}}</div>
          </div>
          <div v-if="PaymentData.zfb_pay_money" class="flex just-sb align-center">
            <div>支付宝支付</div>
            <div>￥{{PaymentData.zfb_pay_money}}</div>
          </div>
          <div v-if="PaymentData.cash_pay_money" class="flex just-sb align-center">
            <div>线下支付</div>
            <div>￥{{PaymentData.cash_pay_money}}</div>
          </div>

          <div v-if="PaymentData.discount_money_sum" style="margin-top:20px" class="flex just-sb align-center size_18 color_FF8400">
            <div>优惠金额</div>
            <div>￥{{PaymentData.discount_money_sum}}</div>
          </div>
          <div v-if="Number(PaymentData.coupon_money)!=0" class="flex just-sb align-center">
            <div>卡券优惠</div>
            <div>￥{{PaymentData.coupon_money}}</div>
          </div>
          <div v-if="Number(PaymentData.discount_money)!=0" class="flex just-sb align-center">
            <div>折扣优惠</div>
            <div>￥{{PaymentData.discount_money}}</div>
          </div>
          <div v-if="PaymentData.change_money" class="flex just-sb align-center">
            <div>收银改价</div>
            <div>￥{{PaymentData.change_money}}</div>
          </div>
          <div v-if="Number(PaymentData.dec_money)!=0" class="flex just-sb align-center">
            <div>新人立减</div>
            <div>￥{{PaymentData.dec_money}}</div>
          </div>
        </div>
        <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import {home,census} from '@/api/money'
import Pie from '@/components/eChart/pie.vue';
import EchartBasic from '@/components/eChart/basic.vue';
import EchartLine from '@/components/eChart/line.vue'
export default {
  components:{Pie,EchartLine,EchartBasic},
  name: "canteen",
  data(){
    return{
      status:false,
      piestatus:false,
      piestatus1:false,
      paymentModal:false,
      PaymentData:{},
      home:{},
      data:{},
      member:{},
      pay_type:{},
      D_model:'1',
      model1:'0',
      model2:'1',
      paymentTitle:'',
      storePrams:{
        start_time:'',
        end_time:''
      },
      basicData:[],
      piedata:[
        {value: 1048, name: '会员用户'},
        {value: 1048, name: '普通用户'}
      ],
      piedata1:[
        {value: 1048, name: '余额单数'},
        {value: 1048, name: '支付宝单数'},
        {value: 1048, name: '微信单数'},
        {value: 1048, name: '现金单数'}
      ],
      piedata2:[
        {value: 1048, name: '卡券优惠'},
        {value: 1048, name: '新人立减'},
        {value: 1048, name: '折扣优惠'},
        {value: 1048, name: '收银改价'}
      ],
      orderList: [
          {
              value: '0',
              label: '所有订单'
          },
          {
              value: '1',
              label: '堂食订单'
          },
          {
              value: '2',
              label: '外卖订单'
          },
          {
              value: '3',
              label: '自取订单'
          }
      ],
      turnoverList: [
          {
              value: '1',
              label: '营业额'
          },
          {
              value: '2',
              label: '订单数'
          }
      ],
      legend: {
        data: ['所有', '外卖', '堂食', '自取']
      },
      series: [],
      xAxis:[]
    }
  },
  created() {
    this.gethome()
    this.census()
  },
  methods:{
    gethome(){
      home().then(res=>{
        this.home = res.data
        this.piedata2[0].value = res.data.money_arr.coupon_money
        this.piedata2[1].value = res.data.money_arr.dec_money
        this.piedata2[2].value = res.data.money_arr.discount_money
        this.piedata2[3].value = res.data.money_arr.charge_money
        this.basicData=  [
            {
            value: res.data.money_arr.cash_pay_money,
                itemStyle: {
                    color: '#FB59C9'
                }
            },
            {
            value: res.data.money_arr.wx_pay_money,
                itemStyle: {
                    color: '#92C50D'
                }
            },
            {
            value: res.data.money_arr.yue_pay_money,
                itemStyle: {
                    color: '#FE771C'
                }
            },
            {
            value: res.data.money_arr.zfb_pay_money,
                itemStyle: {
                    color: '#60AEFE'
                }
            }
        ]
        console.log
        this.piestatus1 = true
      })
    },
    time(e){
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    },
    
    D_change(){
      this.xAxis = []
      this.series = []
      this.legend.data = []
      this.status = false
      let datas = []
      setTimeout(() => {
        if (this.D_model=='1') {
          this.legend.data = ['所有营业额', '外卖营业额', '堂食营业额', '自取营业额']
      
          datas = [
            {
              name: '所有营业额',
              type: 'line',
              data: []
            },
            {
              name: '外卖营业额',
              type: 'line',
              data: []
            },
            {
              name: '堂食营业额',
              type: 'line',
              data: []
            },
            {
              name: '自取营业额',
              type: 'line',
              data: []
            }
          ]
          this.data.date_money.forEach(item => {
            this.xAxis.push(item.date)
            datas[0].data.push(item.val.order_money)
            datas[1].data.push(item.val.w_money)
            datas[2].data.push(item.val.t_money)
            datas[3].data.push(item.val.z_money)
          });
        }else{
          this.legend.data = ['所有订单', '外卖订单', '堂食订单', '自取订单']
         datas = [
            {
              name: '所有订单',
              type: 'line',
              data: []
            },
            {
              name: '外卖订单',
              type: 'line',
              data: []
            },
            {
              name: '堂食订单',
              type: 'line',
              data: []
            },
            {
              name: '自取订单',
              type: 'line',
              data: []
            }
          ]
          this.data.date_num.forEach(item => {
            this.xAxis.push(item.date)
            datas[0].data.push(item.val.order_num)
            datas[1].data.push(item.val.w_num)
            datas[2].data.push(item.val.t_num)
            datas[3].data.push(item.val.z_num)
          });
        }
          this.series = datas
          this.status = true
      }, 10);
      
    },
    // D_change(){
    //   this.census()
    // },
    census(){
      // this.xAxis = []
      // this.series = []
      // this.status = false
      census(this.storePrams).then(res=>{
        this.data = res.data
        this.D_change()
        this.pie_change()
        // if (this.D_model=='1') {
        //   let datas = [
        //     {
        //       name: '所有',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '外卖',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '堂食',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '自取',
        //       type: 'line',
        //       data: []
        //     }
        //   ]
        //   res.data.date_money.forEach(item => {
        //     console.log(item);
        //     this.xAxis.push(item.date)
        //     datas[0].data.push(item.val.order_money)
        //     datas[1].data.push(item.val.w_money)
        //     datas[2].data.push(item.val.t_money)
        //     datas[3].data.push(item.val.z_money)
        //   });
        //   this.series = datas
        //   this.status = true
        // }else{
        //   let datas = [
        //     {
        //       name: '所有',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '外卖',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '堂食',
        //       type: 'line',
        //       data: []
        //     },
        //     {
        //       name: '自取',
        //       type: 'line',
        //       data: []
        //     }
        //   ]
        //   res.data.date_num.forEach(item => {
        //     this.xAxis.push(item.date)
        //     datas[0].data.push(item.val.order_num)
        //     datas[1].data.push(item.val.w_num)
        //     datas[2].data.push(item.val.t_num)
        //     datas[3].data.push(item.val.z_num)
        //   });
        //   this.series = datas
        //   this.status = true
        // }
      })
    },
    Paymentdetails(e){
      // this.PaymentData = e
      if (e==1) {
        this.PaymentData = this.data.t_money_arr
        this.paymentTitle = '堂食营业额明细'
      }else if(e==2){
        this.PaymentData = this.data.w_money_arr
        this.paymentTitle = '外卖营业额明细'
      }else if(e==3){
        this.PaymentData = this.data.z_money_arr
        this.paymentTitle = '自取营业额明细'
      }
      this.paymentModal = true
    },
    pie_change(){
      this.piestatus = false
      let data = [];
      if (this.model1 == 0) {
        data =  this.data.rate.total
      } else if (this.model1 == 1) {
        data =  this.data.rate.t
      } else if (this.model1 == 2) {
        data =  this.data.rate.w
      } else if (this.model1 == 3) {
        data =  this.data.rate.z
      }
      
      this.member = data.member
      this.pay_type = data.pay_type
      setTimeout(()=>{
        this.piedata[0].value = data.member.member_rate
        this.piedata[1].value = data.member.user_rate

        this.piedata1[0].value = data.pay_type.yue_rate
        this.piedata1[1].value = data.pay_type.zfb_rate
        this.piedata1[2].value = data.pay_type.wx_rate
        this.piedata1[3].value = data.pay_type.cash_rate
        this.piestatus = true
      },10)
      
    },
    searchBtn(){
      this.census()
    },
  }
}
</script>

<style scoped>
/* .son-page {

} */
.top-change {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.shu_box{
  width: 70%;
  padding: 20px;
  background: #FFF;
  border-radius: 12px;
}
.bing_box{
  width: 28%;
  background: #FFF;
  border-radius: 12px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 20px 0;
}
.top-search {
  width: 100%;
  background: white;
  padding: 20px 30px 0;
  border-radius: 10px;
  display: flex;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.content_box{
  width: 100%;
  padding: 0 0 0 20px;
  border-radius: 12px;
  background: #FFF;
  margin: 30px 0;
}
.view_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  margin: 30px 0;
}
.Finance_box{
  width: 100%;
  padding: 20px 30px;
  border-radius: 12px;
  background: #FFF;
  margin: 0 0 30px;
}
.three_box{
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  background: #FFF;
  border-radius: 12px;
  padding: 20px 0;
}
.item_three_box{
  width: 32%;
  padding: 0px 30px;
}
.view_right_num{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.turnover_box{
  padding: 20px 10px;
}
.item_view{
  width: 48%;
  background: #fff;
  /* border-radius: 12px; */
}
.view_title{
  background: #F6F6F6;
  display: flex;
  color: #999;
  justify-content: space-around;
}
.view_shuju{
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
}
.w33{
  width: 50%;
  text-align: center;
}
.nei_left{
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
}
.nei_right{
  position: relative;
  height: 100%;
  width: 50%;
}
.pie_select{
  padding: 30px 0 0;
  /* margin: 0 0 20px 0; */
  /* position: absolute;
  top: -50px;
  left: 0; */
}
.dianH::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: #FF761D;
}
.dianL::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: #41B482;
}
.dianX::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: red;
}
.dian::before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
  background: #5DAEFF;
}
.view_left{
  min-width: 470px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
}
.view_right{
  min-width: 470px;
  padding: 30px 20px;
}
.Line::before{
  content: '';
  display: block;
  width: 3px;
  height: 20px;
  background: #FF761D;
  margin-right: 10px;
}
.DW_nei{
  position: absolute;
  top: 0;
  left: 0;
}
.DW_nei_title2::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 1px solid #5DAEFF;
}
.DW_nei_title::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 1px solid #FF761D;
}
.DW_nei_txt{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.Finance_box_title::before{
  content: ' ';
  display: block;
  width: 2px;
  height: 16px;
  margin-right: 10px;
  background: #FF761D;
}
.Line_Txt{
  font-size: 20px;
  font-weight: 700;
}
.item_right_num{
  width: 25%;
  text-align: center;
}
.item_num{
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
}
.just_sb{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.just-sb{
  justify-content: space-between;
}
.check_Print_box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.size_18{
  font-size: 16px;
}
.color_FF8400{
  color: #FF8400;
}
.item_title{
    font-size: 16px;
}
</style>
