<template>
  <div class="son-page">
    <div class="content_box">
      <div class="left_box">
        <div class="yuan_FF761D DW_nei_txt">账户余额</div>
        <div class="member_view_num">{{money}}</div>
        <div class="btns_box">
          <Button style="background:#2DB7F5;color:#FFF" @click="capitalBtn" type="info">资金明细</Button>
          <Button @click="withdrawalBtn" class="btn">提现</Button>
        </div>
      </div>
      <div class="right_box">
        <div class="yuan_FF761D DW_nei_txt">提现方式</div>
        <Form v-if="withdraw_info.withdraw_type==1" class="fenbu" inline label-position="right" :label-width="110">
            <FormItem label="银行支行名称：">
              {{withdraw_info.bank}}
            </FormItem>
            <FormItem label="银行卡号：">
              {{withdraw_info.number}}
            </FormItem>
            <FormItem label="卡主姓名：">
              {{withdraw_info.name}}
            </FormItem>
        </Form>
        <Form v-if="withdraw_info.withdraw_type==2" class="fenbu" inline label-position="left" :label-width="120">
            <FormItem label="姓名：">
              {{withdraw_info.name}}
            </FormItem>
            <FormItem style="display: flex;align-items: center;" label="收款码：">
              <img class="img_box" :src="withdraw_info.collect_code_domain" alt="">
            </FormItem>
        </Form>
        <Form v-if="withdraw_info.withdraw_type==3" class="fenbu" inline label-position="left" :label-width="120">
            <FormItem label="姓名：">
              {{withdraw_info.name}}
            </FormItem>
            <FormItem style="display: flex;align-items: center;" label="收款码：">
              <img class="img_box" :src="withdraw_info.collect_code_domain" alt="">
            </FormItem>
        </Form>
        <div class="end_flex"><Button @click="modeBtn" class="btn">修改</Button></div>
      </div>
    </div>
    <div class="table_box">
      <div class="yuan_FF761D DW_nei_txt">提现记录</div>
      <div class="yiti">已提现：<span class="txt_FF761D">{{amount}}</span></div>
      <Table ref="selection" stripe :columns="worlkColumns" :data="data6">
        <template slot-scope="{ index }" slot="name">
          <div>{{ index + 1 }}</div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div v-if="row.status==1" style="color:#FF9900">申请中</div>
          <div v-if="row.status==2" style="color:#19BE6B">同意</div>
          <div v-if="row.status==3" style="color:red">拒绝</div>
        </template>
        <template slot-scope="{ row }" slot="operation">
          <Button @click="lookBtn(row)" type="info" ghost size="small" style="margin-right: 5px">查看</Button>
        </template>
      </Table>
      <br/>
      <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        v-model="capitalmodal"
        title="资金明细"
        width="900"
        @on-cancel="capitalcancel">
        <Form inline label-position="left" :label-width="0">
            <FormItem label="">
              <Select v-model="amountPrams.status" style="width:200px">
                <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            </FormItem>
            <FormItem label="">
              <DatePicker v-model="amounttime" @on-change="time" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 200px"></DatePicker>
            </FormItem>
            <FormItem label="">
              <Button @click="searchBtn" class="btn">搜索</Button>
            </FormItem>
        </Form>
        <Table style="margin-top:20px" ref="selection" stripe :columns="capitalColumns" :data="data5">
          <template slot-scope="{ index }" slot="name">
            <div>{{ index + 1 }}</div>
          </template>
          <template slot-scope="{ row }" slot="scene">
            {{row.scene_text}}
          </template>
        </Table>
        <br/>
        <Page :total="total1"
              :current="amountPrams.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange1"
              :page-size="amountPrams.limit"/>
        <div slot="footer">
            <!-- <Button @click="capitalcancel" class="btn">关闭</Button> -->
        </div>
    </Modal>
    <Modal
        v-model="withdrawalmodal"
        title="提现"
        width="500"
        @on-cancel="withdrawalcancel">
        <Form label-position="left" :label-width="110">
            <FormItem label="可提现余额：">{{money}}</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==1" label="提现方式：">银行转账</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==2" label="提现方式：">微信</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==3" label="提现方式：">支付宝</FormItem>
            <FormItem label="提现金额：">
              <Input @input="inputChange" v-model="moneys" placeholder="请输入提现余额"></Input>
            </FormItem>
            <FormItem label="手续费："><span class="txt_FF761D">{{brokerage}}</span></FormItem>
            <FormItem label="实际到账：">{{shiji}}</FormItem>
        </Form>
        <div slot="footer">
            <Button @click="withdrawalcancel">取消</Button>
            <Button @click="withdrawaladd" class="btn">提现</Button>
        </div>
    </Modal>
    <Modal
        v-model="modemodal"
        title="修改提现方式"
        width="500"
        @on-cancel="modecancel">
        <Form label-position="right" :label-width="110">
            <FormItem class="mb15" label="提现方式：">
              <Select @on-change="jianting" v-model="editData.withdraw_type">
                  <Option v-for="item in modeselect" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <div v-if="editData.withdraw_type=='1'">
              <FormItem class="mb15" label="银行支行名称：">
                <Input v-model="editData.bank" placeholder="请输入银行支行名称"></Input>
              </FormItem>
              <FormItem class="mb15" label="卡号：">
                <Input v-model="editData.number" placeholder="请输入卡号"></Input>
              </FormItem>
              <FormItem class="mb15" label="卡主姓名：">
                <Input v-model="editData.name" placeholder="请输入卡主姓名"></Input>
              </FormItem>
            </div>
            <div v-if="editData.withdraw_type=='2'">
              <FormItem class="mb15" label="真实姓名：">
                <Input v-model="editData.name" placeholder="请输入真实姓名"></Input>
              </FormItem>
              <FormItem class="mb15" label="收款码：">
                <div style="display:flex">
                  <div>
                      <Upload
                      ref="upload"
                      :show-upload-list="false"
                      :format="['jpg','jpeg','png']"
                      :max-size="2048"
                      :headers="header"
                      :on-format-error="handleFormatError"
                      :on-success="uploadImgSuccess"
                      :on-progress="handleProgress"
                      multiple
                      :action="uploads">
                          <div class="uplads_box">
                              <div style="text-align:center">
                                  <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                                  <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
                              </div>
                          </div>
                      </Upload>
                  </div>
                  <div v-if="imgview" class="img_view_Box">
                    <img :src="imgview" alt="">
                  </div>
                </div>
              </FormItem>
            </div><div v-if="editData.withdraw_type=='3'">
              <FormItem class="mb15" label="真实姓名：">
                <Input v-model="editData.name" placeholder="请输入真实姓名"></Input>
              </FormItem>
              <FormItem class="mb15" label="收款码：">
                <div style="display:flex">
                  <div>
                      <Upload
                      ref="upload"
                      :show-upload-list="false"
                      :format="['jpg','jpeg','png']"
                      :max-size="2048"
                      :headers="header"
                      :on-format-error="handleFormatError"
                      :on-success="uploadImgSuccess"
                      :on-progress="handleProgress"
                      multiple
                      :action="uploads">
                          <div class="uplads_box">
                              <div style="text-align:center">
                                  <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                                  <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
                              </div>
                          </div>
                      </Upload>
                  </div>
                  <div v-if="imgview" class="img_view_Box">
                    <img :src="imgview" alt="">
                  </div>
                </div>
              </FormItem>
            </div>
        </Form>
        <div slot="footer">
            <Button @click="modecancel">取消</Button>
            <Button @click="modeadd" class="btn">保存</Button>
        </div>
    </Modal>
    <Modal
        v-model="statemodal"
        title="详情"
        width="400"
        @on-cancel="statcancel">
        <Form v-if="desc.status==2" label-position="right" :label-width="110">
            <FormItem label="提现金额：">{{desc.with_money}}</FormItem>
            <FormItem label="提现手续费：">{{desc.brokerage}}</FormItem>
            <FormItem label="提现后余额：">{{desc.data_after_money}}</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==1" label="提现方式：">银行转账</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==2" label="提现方式：">微信</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==3" label="提现方式：">支付宝</FormItem>
            <FormItem v-if="withdraw_info.withdraw_type==1" label="银行卡号：">{{withdraw_info.number}}</FormItem>
            <FormItem label="姓名：">{{withdraw_info.name}}</FormItem>
            <FormItem label="提现时间：">{{desc.created_at}}</FormItem>
        </Form>
        <div v-else>{{desc.desc}}</div>
        <div slot="footer">
            <Button @click="statcancel" class="btn">取消</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import config from '@/config';
import {getCookies} from '@/utils/helper'
import {withdrawRecord,amountDetail,getWithdrawData,setWithInfo,getBrokerage,financewithdraw} from '@/api/money'
export default {
  name: "canteen",
  data(){
    return{
      header:{

          'hlzw-canyin-authori-zation':getCookies('hlzw_canyin_token')
      },
      uploads: config.apiUrl + '/storeapi/data/uploads',
      money:'',
      moneys:'',
      brokerage:'',
      shiji:'',
      desc:{},
      imgview:'',
      withdraw_info:{},
      amounttime:'',
      zf:true,
      capitalmodal:false,
      statemodal:false,
      withdrawalmodal:false,
      modemodal:false,
      amount:'',
      total:0,
      storePrams:{
        page:1,
        limit:10
      },
      editData:{
        withdraw_type:'1',
        bank:'',
        number:'',
        name:'',
        collect_code:''
      },
      data5:[],
      data6:[],
      worlkColumns:[
        {
          title: '#',
          slot: 'name',
          align: 'center'
        },
        {
          title: '提现金额',
          key: 'with_money',
          align: 'center'
        },
        {
          title: '提现时余额',
          key: 'data_before_money',
          align: 'center'
        },
        {
          title: '提现后余额',
          key: 'data_after_money',
          align: 'center'
        },
        {
          title: '提现时间',
          key: 'created_at',
          align: 'center'
        },
        {
          title: '提现状态',
          slot: 'status',
          width: 180,
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 160,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      capitalColumns:[
        {
          title: '#',
          slot: 'name',
          align: 'center'
        },
        {
          title: '金额',
          key: 'money',
          align: 'center'
        },
        {
          title: '类型',
          slot: 'scene',
          align: 'center'
        },
        {
          title: '订单ID',
          key: 'order_id',
          align: 'center'
        },
        {
          title: '用户编号',
          key: 'user_number',
          align: 'center'
        },
        {
          title: '用户昵称',
          key: 'name',
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'created_at',
          align: 'center'
        },
      ],
      model:'0',
      cityList: [
          {
              value: '-1',
              label: '堂食'
          },
          {
              value: '-2',
              label: '外卖'
          },
          {
              value: '-3',
              label: '自取'
          },
          {
              value: '0',
              label: '所有'
          },
          {
              value: '1',
              label: '会员开卡'
          },
          {
              value: '2',
              label: '会员充值'
          },
          {
              value: '1001',
              label: '订单退款'
          },
          {
              value: '1002',
              label: '提现'
          }
      ],
      total1:0,
      amountPrams:{
        page:1,
        limit:10,
        status:'0',
        start_time:'',
        end_time:''
      },
      modelvalue:'0',
      modeselect:[
          {
              value: '1',
              label: '银行转账'
          },
          {
              value: '2',
              label: '微信'
          },
          {
              value: '3',
              label: '支付宝'
          }
      ],
    }
  },
  created() {
    this.withdrawRecord()
    this.getWithdrawData()
  },
  methods:{
    withdrawRecord(){
      withdrawRecord(this.storePrams).then(res=>{
        this.amount = res.data.amount
        this.data6 = res.data.data
        this.total = res.data.total
      })
    },
    amountDetail(){
      amountDetail(this.amountPrams).then(res=>{
        this.data5 = res.data.data
        this.total1 = res.data.total
      })
    },
    getWithdrawData(){
      getWithdrawData().then(res=>{
        this.withdraw_info = res.data.withdraw_info
        this.money = res.data.amount
      })
    },
    jianting(){
      this.imgview = ''
      this.editData.bank = ''
      this.editData.number = ''
      this.editData.name = ''
      this.editData.collect_code = ''
    },
    searchBtn(){
      this.amountDetail()
    },
    time(e){
      this.amountPrams.start_time = e[0]
      this.amountPrams.end_time = e[1]
    },
    lookBtn(e){
      console.log(e);
      this.desc = e
      this.statemodal = true
    },
    inputChange(){
      getBrokerage({money:this.moneys}).then(res=>{
        this.brokerage = res.data.brokerage
        this.shiji = this.moneys - res.data.brokerage
      })
    },
    statcancel(){
      this.statemodal = false
    },
    capitalBtn(){
      this.amountDetail()
      this.capitalmodal = true
    },
    capitalcancel(){
      this.amounttime = ''
      this.amountPrams = {
        page:1,
        limit:10,
        status:'0',
        start_time:'',
        end_time:''
      }
      this.capitalmodal = false
    },
    withdrawalBtn(){
      this.withdrawalmodal = true
    },
    withdrawaladd(){
      financewithdraw({money:this.moneys}).then(res=>{
        this.shiji = ''
        this.brokerage = ''
        this.moneys = ''
        this.withdrawalmodal = false
        this.getWithdrawData()
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    withdrawalcancel(){
      this.shiji = ''
      this.brokerage = ''
      this.moneys = ''
      this.withdrawalmodal = false
    },
    modeBtn(){
      this.modemodal = true
    },
    modeadd(){
      setWithInfo(this.editData).then(res=>{
        this.getWithdrawData()
        this.$Message.success(res.msg)
        this.editData = {
          withdraw_type:'1',
          bank:'',
          number:'',
          name:'',
          collect_code:''
        },
        this.modemodal = false
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    modecancel(){
      this.editData = {
        withdraw_type:'1',
        bank:'',
        number:'',
        name:'',
        collect_code:''
      },
      this.modemodal = false
    },
    //文件上传类型错误
    handleFormatError () {
        this.$Message.warning('暂不支持上传此格式');
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e){
        this.imgview = e.data.path
        this.editData.collect_code = e.data.src
        this.$Message.destroy()
        this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress(){
        const msg = this.$Message.loading({
            content: '上传中...',
            duration: 0
        });
        setTimeout(msg, 3000);
    },
    pageChange(index){
      this.storePrams.page = index
      this.withdrawRecord()
    },
    pageChange1(index){
      this.amountPrams.page = index
      this.amountDetail()
    },
  }
}
</script>

<style scoped>
/* .son-page {

} */
.content_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.left_box{
  width: 22%;
  padding: 20px 30px;
  border-radius: 12px;
  background: #FFF;
}
.right_box{
  width: 74%;
  padding: 20px 30px;
  border-radius: 12px;
  background: #FFF;
}
.mb15{
  margin-bottom: 15px!important;
}
.yuan_FF761D::before{
  content: ' ';
  display: block;
  width: 2px;
  height: 16px;
  margin-right: 10px;
  background: #FF761D;
}
.DW_nei_txt{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.member_view_num{
  text-align: center;
  line-height: 100px;
  font-size: 28px;
  font-weight: 700;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.btns_box{
  display: flex;
  justify-content: space-between;
}
.img_box{
  width: 100px;
  height: 100px;
  margin-left: -100px;
  border: 1px solid #999;
  border-radius: 5px;
  object-fit: cover;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.fenbu{
  display: flex;
  align-items: center;
  height: 100px;
  /* justify-content: space-between; */
}
.img_view_Box{
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-left: 20px;
  overflow: hidden;
}
.img_view_Box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ivu-form-item{
  margin-bottom: 0;
  margin-right: 70px;
}
.end_flex{
  display: flex;
  justify-content: flex-end;
}
.table_box{
  width: 100%;
  margin-top: 30px;
  background: #FFF;
  border-radius: 12px;
  padding: 20px 30px;
}
.txt_FF761D{
  color: #FF761D;
}
.yiti{
  margin-left: 10px;
  padding: 20px 0;
}
</style>
