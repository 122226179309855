import request from '@/utils/request'

export function home (data) {
    return request({
        url: '/revenue/home/home',
        method: 'post',
        data
    })
}

export function goodshome (data) {
    return request({
        url: '/revenue/goods/home',
        method: 'post',
        data
    })
}

export function goodssalas (data) {
    return request({
        url: '/revenue/goods/salas',
        method: 'post',
        data
    })
}

export function memberhome (data) {
    return request({
        url: '/revenue/member/home',
        method: 'post',
        data
    })
}

export function memberData (data) {
    return request({
        url: '/revenue/member/memberData',
        method: 'post',
        data
    })
}

export function memberAddData (data) {
    return request({
        url: '/revenue/member/memberAddData',
        method: 'post',
        data
    })
}

export function withdrawRecord (data) {
    return request({
        url: '/revenue/finance/withdrawRecord',
        method: 'post',
        data
    })
}

export function amountDetail (data) {
    return request({
        url: '/revenue/finance/amountDetail',
        method: 'post',
        data
    })
}

export function getWithdrawData (data) {
    return request({
        url: '/revenue/finance/getWithdrawData',
        method: 'post',
        data
    })
}

export function setWithInfo (data) {
    return request({
        url: '/revenue/finance/setWithInfo',
        method: 'post',
        data
    })
}

export function census (data) {
    return request({
        url: '/revenue/home/census',
        method: 'post',
        data
    })
}

export function memberRate (data) {
    return request({
        url: '/revenue/home/memberRate',
        method: 'post',
        data
    })
}

export function getBrokerage (data) {
    return request({
        url: '/revenue/finance/getBrokerage',
        method: 'post',
        data
    })
}

export function financewithdraw (data) {
    return request({
        url: '/revenue/finance/withdraw',
        method: 'post',
        data
    })
}