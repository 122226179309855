<template>
  <div class="page-style">
    <div class="left-menu">
      <div class="menu-nav" v-for="(item,index) in leftMenu"
           :class="activeIndex === item.src ? 'active' : ''"
           :key="index" @click="changeLabel(item)">
        <Icon :type="item.icon" size="24" style="margin-right: 10px"/>
        {{ item.title }}</div>
    </div>
    <div class="right-content">
      <div class="content-del">
        <div v-if="activeIndex === '/admin/money/overview'"><Canteen></Canteen></div>
        <div v-if="activeIndex === '/admin/money/Statistics'"><Takeout></Takeout></div>
        <div v-if="activeIndex === '/admin/money/membership'"><Membership></Membership></div>
        <div v-if="activeIndex === '/admin/money/financial'"><Financial></Financial></div>
      </div>
    </div>
  </div>
</template>
<script>
import Canteen from "./components/canteen";
import Takeout from "./components/takeout";
import Membership from "./components/membership";
import Financial from "./components/financial";
export default {
  components: {Takeout,Canteen,Membership,Financial},
  data() {
    return {
      leftMenu:[
        {
          icon:'ios-cafe-outline',
          label:'总览',
          path:'/admin/money/overview'
        },
        {
          icon:'ios-pricetags-outline',
          label:'商品统计',
          path:'/admin/money/Statistics'
        },
        {
          icon:'ios-contacts-outline',
          label:'会员统计',
          path:'/admin/money/membership'
        },
        {
          icon:'ios-calculator-outline',
          label:'财务管理',
          path:'/admin/money/financial'
        }
      ],
      activeIndex:0
    }
  },
  mounted() {
  },
  created() {
    let menu = this.$store.state.menu
    menu.forEach(item => {
      if (item.id==16) {
        if (this.$store.state.activeIndex==0) {
          this.activeIndex = item.children[this.$store.state.activeIndex].src
        }else{
          this.activeIndex = this.$store.state.activeIndex
        }
        this.leftMenu = item.children
      }
    });
  },
  methods: {
    changeLabel(item){
      this.$store.commit('setActiveIndex', item.src)
      this.activeIndex = item.src
    }
  }
}
</script>
<style scoped>
.page-style {
  display: flex;
}

.left-menu {
  width: 210px;
  /* max-width: 210px;
  min-width: 210px; */
  height: 93vh;
  background: white;
  margin-right: 20px;
  padding: 20px 20px;
  box-sizing: border-box;
}

.right-content {
  /* flex: 1; */
  width: calc(100% - 230px);
  padding: 20px 20px 0 0;
  box-sizing: border-box;
}
.content-del {
  /*background: #fff;*/
  width: 100%;
  height: 88vh;
  border-radius: 8px;
  overflow: auto;
}
.menu-nav {
  border: 1px solid #FFF;
  position: relative;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 200px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.menu-nav:hover{
  background: #FFF7F2;
  color: #686868;
}
.menu-nav.active{
  /* background: #FF761D; */
  border: 1px solid #FF761D;
  color: #FF761D;
  /* color: white; */
}
.floating{
  position: absolute;
  top: -5px;
  right: 10px;
  min-width: 15px;
  height: 15px;
  border-radius: 100%;
  background: red;
  color: #FFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
