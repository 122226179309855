<template>
  <div class="son-page">
    <div class="one_box">
      <div class="left_box">
        <div class="left_box_txt">会员数据</div>
        <div class="table_title">
          <div class="item_title">排序</div>
          <div class="item_title">会员等级</div>
          <div class="item_title">会员数量</div>
          <div class="item_title">会员订单数</div>
          <div class="item_title">会员订单总额</div>
        </div>
        <div style="height: 283px;overflow: auto;min-height:250px">
          <!-- <div style="line-height:50px;color:#999;text-align:center">暂无数据~</div> -->
          <div v-for="(item,index) of data" :key="index" class="table_content">
            <div class="item_content">{{index+1}}</div>
            <div class="item_content">{{item.level_name}}</div>
            <div class="item_content">{{item.user_count}}</div>
            <div class="item_content">{{item.order_count}}</div>
            <div class="item_content FF761D">{{item.order_sum_money}}</div>
          </div>
        </div>
        <!-- <Page :total="total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator
            show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/> -->
      </div>
      <div class="right_box">
        <div style="margin-bottom:36px" class="member_view">
          <div class="yuan_FF761D DW_nei_txt">会员数</div>
          <div class="member_view_num">{{memberhomedata.user_count}}</div>
        </div>
        <div style="margin-bottom:36px" class="member_view">
          <div class="yuan_75BAFF DW_nei_txt">会员卡费</div>
          <div class="member_view_num">{{memberhomedata.member_card}}</div>
        </div>
        <div class="member_view">
          <div class="yuan_95C717 DW_nei_txt">会员充值</div>
          <div class="member_view_num">{{memberhomedata.member_recharge}}</div>
        </div>
      </div>
    </div>
    <div class="content_box">
      <div class="txt">会员新增数据</div>
      <div class="search_box">
        <DatePicker @on-change="time" type="daterange" placement="bottom-end" placeholder="点击选择日期" style="width: 200px"></DatePicker>
        <Button @click="searchBtn" class="btn ml20">搜索</Button>
      </div>
      <EchartLine v-if="status" id="aaaaaa" :xAxis="xAxis" :series="series" width="100%"></EchartLine>
    </div>
  </div>
</template>

<script>
import {memberhome,memberData,memberAddData} from '@/api/money'
import EchartLine from '@/components/eChart/line.vue'
export default {
  components:{EchartLine},
  name: "canteen",
  data(){
    return{
      status:false,
      memberhomedata:{},
      data:[],
      total:0,
      storePrams:{
        start_time:'',
        end_time:''
      },
      xAxis:[],
      series:[{type:'line',data:[]}]
    }
  },
  created() {
    this.getmemberhome()
    this.getList()
    this.memberAddData()
  },
  methods:{
    getmemberhome(){
      memberhome().then(res=>{
        this.memberhomedata = res.data
      })
    },
    getList(){
      memberData().then(res=>{
        this.data = res.data
      })
    },
    time(e){
      this.storePrams.start_time = e[0]
      this.storePrams.end_time = e[1]
    },
    memberAddData(){
      this.status = false
      this.xAxis = []
      this.series = [{type:'line',data:[]}]
      memberAddData(this.storePrams).then(res=>{
        res.data.forEach(item => {
          this.xAxis.push(item.date)
          this.series[0].data.push(item.val)
        });
        this.status = true
      })
    },
    searchBtn(){
      this.memberAddData()
    },
    pageChange(){}
  }
}
</script>

<style scoped>
/* .son-page {

} */
.one_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.content_box{
  width: 100%;
  padding: 0 0 0 20px;
  border-radius: 12px;
  background: #FFF;
  margin: 30px 0;
}
.left_box_txt{
  font-size: 20px;
  font-weight: 700;
}
.txt{
  font-size: 20px;
  font-weight: 700;
  padding: 20px 10px;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.ml20{
  margin-left: 20px;
}
.left_box{
  width: 74%;
  padding: 20px 30px;
  background: #FFF;
  border-radius: 12px;
}
.right_box{
  width: 22%;
  border-radius: 12px;
}
.table_title{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #F4F4F6;
  padding: 10px 0;
  height: 41px;
  border-radius: 20px;
}
.item_title{
  width: 20%;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
}
.table_content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: 50px;
  border-radius: 20px;
}
.FF761D{
  color: #FF761D!important;
}
.item_content{
  width: 20%;
  font-weight: 700;
  overflow: hidden;
  color: #999;
  text-align: center;
}
.member_view{
  width: 100%;
  padding: 20px 30px;
  background: #FFF;
  border-radius: 12px;
}
.yuan_FF761D::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #FF761D;
}
.yuan_75BAFF::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #75BAFF;
}
.yuan_95C717::before{
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #95C717;
}
.DW_nei_txt{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.member_view_num{
  text-align: center;
  line-height: 50px;
  font-size: 28px;
  font-weight: 700;
}
</style>
